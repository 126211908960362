import * as types from 'store/media/types';

const initialState = {
  loading: false,
  television: [],
  radio: [],
  errors: {},
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_TELEVISION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_TELEVISION_SUCCESS:
      return {
        ...state,
        loading: false,
        television: action.payload,
      };
    case types.LOAD_TELEVISION_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.LOAD_RADIO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_RADIO_SUCCESS:
      return {
        ...state,
        loading: false,
        radio: action.payload,
      };
    case types.LOAD_RADIO_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default mediaReducer;
