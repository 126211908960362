import { combineReducers } from 'redux';
import { persistReducer, PURGE } from 'redux-persist';
import persistConfig from 'config/persist';

import categoryReducer from 'store/category/reducers';
import mediaReducerr from 'store/media/reducers';
import maaveerarReducer from 'store/maaveerar/reducers';
import postReducer from 'store/post/reducers';
import genocideReducer from './genocides/reducers';
import essayReducer from './essay/reducers';

const appReducer = combineReducers({
  post: persistReducer(persistConfig, postReducer),
  category: categoryReducer,
  media: mediaReducerr,
  maaveerar: maaveerarReducer,
  genocide: genocideReducer,
  essay: essayReducer,
});

const rootReducer = (state, action) => {
  if (action.type === PURGE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
