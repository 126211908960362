import * as types from 'store/essay/types';

const initialState = {
  loading: false,
  essays: [],
  essay: {},
  errors: {},
};

const essayReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ESSAYS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_ESSAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        essays: action.payload,
      };
    case types.LOAD_ESSAYS_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_ESSAY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_ESSAY_SUCCESS:
      return {
        ...state,
        loading: false,
        essay: action.payload,
      };
    case types.LOAD_ESSAY_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default essayReducer;
