import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Router } from 'Router';
import store, { persistor } from './store';
import ErrorBoundary from 'components/ErrorBoundary';
import Loading from 'components/Loading';
import { name, lang } from 'config/env';

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate
          loading={<Loading />}
          persistor={persistor}
        >
          <HelmetProvider>
            <Helmet>
              <html lang={lang} />
              <title>{name}</title>
            </Helmet>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
