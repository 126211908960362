import * as types from 'store/genocides/types';

const initialState = {
  loading: false,
  genocides: [],
  genocide: {},
  errors: {},
};

const genocideReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_GENOCIDES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_GENOCIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        genocides: action.payload,
      };
    case types.LOAD_GENOCIDES_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_GENOCIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_GENOCIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        genocide: action.payload,
      };
    case types.LOAD_GENOCIDE_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default genocideReducer;
