import React from 'react';
import Loading from 'components/Loading';

function Index({ page }) {
  const Component = React.useMemo(() => {
    return React.lazy(() => import(`pages/${page}`));
  }, [page]);
  return (
    <React.Suspense fallback={<Loading />}>
      <Component />
    </React.Suspense>
  );
}

export default Index;
