import React from 'react';
import * as routes from 'constants/routes';
import { Route, Routes } from 'react-router-dom';
import NoMatch from 'pages/NoMatch';
import App from 'layouts/App';
import LazyPage from 'components/LazyPage';

function Router() {
  return (
    <Routes>
      <Route path={routes.HOME} element={<App />}>
        <Route index element={<LazyPage page='Index' />} />
      </Route>

      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
}

export default Router;
