import axios from 'config/axois';
import * as types from 'store/category/types';

export const loadCategories = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.LOAD_CATEGORY_REQUEST });
    const { data } = await axios.get('/web/categories');
    dispatch({
      type: types.LOAD_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_CATEGORY_FAILED,
      payload: error,
    });
  }
};
