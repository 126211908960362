import React from 'react';
import Lottie from 'lottie-react';
import animation from 'assets/animations/lading-animation.json';

function Index() {
  return (
    <div className='flex justify-center	justify-items-center 	'>
      <Lottie style={customStyles.style} animationData={animation} />
    </div>
  );
}

export default Index;

const customStyles = {
  style: {
    width: '25px',
    height: '25px',
  },
};
